<template class="bg-white h-full">
  <div class="bg-white h-full">
    <div class="h-44 bg-gray-800 flex items-center justify-center mt-9 lg:mt-0">
      <h1 class="font-bold lg:text-4xl text-xl text-white">
        {{ $t("forum_rules") }}
      </h1>
    </div>
    <div class="text-center lg:ml-44 lg:mr-44 bg-gray-100 p-4 mx-4 lg:mx-0">
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
       {{ $t("forum_specific_terms") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        {{ $t("article_1_definitions") }}
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        <strong>•</strong> {{ $t("member_definition") }}<br />
        <strong>•</strong>{{ $t("contribution_definition") }}<br />
        <strong>•</strong> {{ $t("discussion_definition") }}<br />
        <strong>•</strong> {{ $t("forum_definition") }}<br />
        <strong>•</strong> {{ $t("moderation_definition") }}<br />
        <strong>•</strong>{{ $t("moderator_definition") }}<br />
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">{{ $t("article_2_title") }}</h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
       {{ $t("forum_special_conditions") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
       {{ $t("registration_procedures") }}
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        {{ $t("member_must_register") }}<br />
       {{ $t("member_email_confidential") }} <br />{{ $t("member_anonymity_protection") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        {{ $t("article_title") }}
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        {{ $t("forum_discussions_responsibility") }}<br />
        {{ $t("forum_discussions_rules") }}<br />
        • {{ $t("forum_contribution_prohibition") }}<br />
        • {{ $t("forum_data_prohibition") }}<br />
        • {{ $t("forum_prohibition_commercial") }}<br />
        • {{ $t("forum_discussion_title") }}<br />
        • {{ $t("forum_new_question") }}<br />
        • {{ $t("forum_member_conduct") }}<br />
        • {{ $t("forum_no_personal_attacks") }}<br />
        • {{ $t("forum_no_copyright_infringement") }}<br />
        • {{ $t("forum_no_copying_without_permission") }}<br />
        {{ $t("forum_report_violation") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        {{ $t("article_57_title") }}
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        {{ $t("article_5_content") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        {{ $t("article_67_title") }}
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        {{ $t("article_6_violation") }}
      </p>

      <div class="lg:flex mt-6">
        <button @click="Accepted()" >
          <div
            class="flex bg-blue-500 rounded-full py-2 px-2 ml-9 lg:ml-0 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-green-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
              />
            </svg>

            <p class="ml-2 mt-1 font-sans"><span v-if="isLoading">
              <spiner />
            </span>
            <span v-else> {{ $t("accept") }}</span></p>
          </div>
        </button>
        <router-link to="/" class="ml-9">
          <div
            class="flex bg-blue-500 rounded-full py-2 px-2 ml-9 lg:ml-0 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-red-700"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>

            <p class="ml-2 mt-1 font-sans">{{ $t("refuse") }}</p>
          </div>
        </router-link>
      </div>
    </div>

    <div class="h-6"></div>
  </div>
</template>
  
<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
export default {
  name: "App",
  components: {
    spiner,
 
  },
  data() {
    return {
    addform: {
        user_id: "",
      },
      user: "",
      isLoading: false,
    };
  },
   mounted() { 
    this.profile();
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
          //console.log(this.user);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
   
    async Accepted() {
      try {
        this.isLoading = true;
        this.addform.user_id = this.user;
        const response = await axios.post("/api/condictions", this.addform);
        if (response.status === 201) {
          this.addform = {};
          
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Merci d'avoir accepter les condicitons d'utilisation.",
            timeout: 5000,
          }).show();
          this.$router.push("/question");
        }  if (response.status === 302) {
          this.showAlert = true;
            this.isLoading = false;
          this.alert.message = "Veuillez vous connecter, s'il vous plaît.";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
  },
};
</script>
  
<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
</style>
  