<template>
  <div class="">
    <div class="relative w-full flex items-center justify-center">
      <div class="absolute text-center lg:top-2 top-6">
        <h1 class="lg:text-4xl text-lg text-gray-500 font-mono">{{ $t('why') }}</h1>
        <h1 class="lg:text-4xl text-lg text-blue-500 font-mono">{{ $t('learn') }}</h1>
      </div>
      <img v-if="this.selectedLang === 'fr'" class="w-full mt-16" src="../assets/femmeplusfond.png" alt="" />
      <img v-else class="w-full mt-16" src="../assets/femmeplusfonden.png" alt="" />
      <div class="absolute text-center lg:bottom-10 -bottom-4">
        <h2 class="lg:text-2xl text-sm text-white font-serif">
          {{ $t('we_create_quality_content') }} <br />
          {{ $t('and_due_to_our_experience') }}<br />
          <spam
            >.........................................................................</spam
          >
        </h2>
      </div>
    </div>
    <div class="lg:flex lg:mx-16 mt-9 mx-6">
      <img
        class="md:mx-auto border-4 border-purple-900 rounded-lg sm:mx-auto"
        src="../assets/IMG_2020822.jpg"
        alt=""
      />

      <div class="lg:w-2/3 mt-6">
        <h1
          class="text-blue-500 lg:text-3xl text-xl mt-4 lg:text-left lg:ml-12 font-mono md:mx-auto md:text-center"
        >
          {{ $t('what_do_you_want_to_learn') }}
        </h1>

        <p
          class="text-gray-700 lg:text-2xl text-sm font-serif mt-2 lg:mr-12 lg:ml-12 lg:text-left"
        >
         {{ $t('our_tutorials_cover') }}
        </p>
      </div>
    </div>
    <div class="mt-6 mx-6 lg:mx-0">
      <h1 class="lg:text-4xl font-serif lg:mr-12 lg:ml-12 text-xl">
        {{ $t('we_look_forward_to_seeing_you_follow_our') }}
        <spam class="text-blue-400">{{ $t('tutorials') }},</spam> {{ $t('listed_below') }}
      </h1>
    </div>
    <div class="flex mt-6 flex-wrap  justify-center">
      <div
        v-for="(video, index) in sortedVideos"
        :key="index"
        class="lg:w-1/4 p-4 md:mx-auto lg:mx-0 "
      >
        <div
          class="bg-purple-900 mt-4 transition transform duration-700 hover:shadow-xl hover:scale-105 rounded-lg relative"
        >
          <iframe
            class="rounded-lg w-full h-48 lg:py-0 lg:pr-0 mt-2"
            :src="
              video.video_url.includes('youtube')
                ? video.video_url.replace('watch?v=', 'embed/')
                : video.video_url
            "
            allowfullscreen
          ></iframe>

          <div class="flex flex-col text-left   mx-2">
            <h2 class="text-white text-lg font-bold ">
              {{ video.name }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-9 mb-4" v-if="totalPages > 1">
      <nav aria-label="Page navigation example">
        <ul class="flex items-center -space-x-px h-12 text-sm">
          <li v-if="currentPage > 1">
            <a
              @click.prevent="goToPage(currentPage - 1)"
              class="flex items-center justify-center px-3 h-12 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Previous</span>
              <svg
                class="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </a>
          </li>
          <li v-for="page in pages" :key="page">
            <a
              @click.prevent="goToPage(page)"
              :class="{
                'z-10 flex items-center justify-center px-3 h-12 leading-tight': true,
                'text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white':
                  currentPage === page,
                'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white':
                  currentPage !== page,
              }"
            >
              {{ page }}
            </a>
          </li>
          <li v-if="currentPage < totalPages">
            <a
              @click.prevent="goToPage(currentPage + 1)"
              class="flex items-center justify-center px-3 h-12 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Next</span>
              <svg
                class="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="h-9"></div>
  </div>
</template>

<script>
import axios from "axios";
import TokenService from "../services/storage.service";
export default {
  name: "Video",
  data() {
    return {
      videos: [],
      currentPage: 1,
      itemsPerPage: 16,
       selectedLang: "FR",
    };
  },
  mounted() {
    this.getVideos();
  },
   created() {
    this.selectedLang = TokenService.getLang();
    //this.profile();
  },
  computed: {
    sortedVideos() {
      // Triez les vidéos par date de création dans l'ordre décroissant
      return this.videos
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
    totalPages() {
      return Math.ceil(this.sortedVideos.length / this.itemsPerPage);
    },
    paginatedCards() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      // Vérifier si la page actuelle est valide
      if (startIndex >= this.sortedVideos.length || endIndex <= 0) {
        return []; // Retourner un tableau vide si la page est invalide
      }

      return this.sortedVideos.slice(startIndex, endIndex);
    },
    // Vérifie si le bouton "Charger plus" doit être affiché
    showLoadMoreButton() {
      return this.currentPage * this.itemsPerPage < this.sortedVideos.length;
    },
    // pages() {

    //   return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    // },
    pages() {
      const totalCards = this.sortedVideos.length;
      const totalPages = Math.ceil(totalCards / this.itemsPerPage);
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    },
  },

  methods: {
    async getVideos() {
      try {
        axios.defaults.headers.common['lang'] = TokenService.getLang();
        const response = await axios.get("/api/videos");
        if (response.data) {
          this.videos = response.data.data;
          //console.log(this.videos);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    nextSlide() {
      if (this.currentIndex + 3 < this.slides.length) {
        this.currentIndex += 1;
        this.updateSlider();
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
        this.updateSlider();
      }
    },
    updateSlider() {
      const translateValue = -this.currentIndex * (100 / 3) + "%";
      this.$refs.slider.style.transform = "translateX(" + translateValue + ")";
    },
    goToPage(page) {
      this.currentPage = page;
      console.log(window.innerHeight);
      window.scrollTo(0, window.innerHeight / 2); // Défilement jusqu'au quart de la page
    },

    loadMore() {
      // Ajoutez ici la logique pour charger plus d'éléments depuis votre source de données (définie localement)
      // Assurez-vous d'ajuster cette logique en fonction de votre situation réelle
      try {
        const newCards = this.getMoreCards(); // Remplacez par votre logique pour récupérer plus de cartes localement
        this.cards = this.cards.concat(newCards);
        this.currentPage++;
      } catch (error) {
        console.error(
          "Erreur lors du chargement des données supplémentaires",
          error
        );
      }
    },
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
